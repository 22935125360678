@size-medium: 18px;
@size-small: 16px;
@size-extrasmall: 14px;
@size-tiny: 12px;
@line-height-medium: 24px;
@line-height-small: 20px;
@defaultFontFamily: 'Inter', sans-serif;
.inter-h1 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
}
.inter-h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
.inter-h3 {
  font-size: @size-medium;
  font-weight: 700;
  line-height: 26px;
}
.inter-h4 {
  font-size: @size-small;
  font-weight: 700;
  line-height: @line-height-medium;
}
.inter-h5 {
  font-size: @size-medium;
  font-weight: 400;
  line-height: 26px;
}
.inter-buttons_tabs {
  font-size: @size-small;
  font-weight: 500;
  line-height: @line-height-medium;
  :hover {
    text-decoration: underline;
  }
}
.inter-p1 {
  font-size: @size-small;
  font-weight: 400;
  line-height: @line-height-medium;
}
.inter-p2 {
  font-size: @size-small;
  font-weight: 400;
  line-height: @line-height-medium;
  text-decoration: underline;
}
.inter-p3 {
  font-size: @size-extrasmall;
  font-weight: 400;
  line-height: @line-height-small;
}
.inter-caption {
  font-size: @size-extrasmall;
  font-weight: 500;
  line-height: @line-height-small;
}
.inter-caption_bold {
  font-size: @size-extrasmall;
  line-height: @line-height-small;
  font-weight: 700;
}
.inter-caption_medium {
  font-size: @size-tiny;
  line-height: 18px;
  font-weight: 400;
}
.inter-caption_m_bold {
  font-size: @size-tiny;
  line-height: 18px;
  font-weight: 500;
}
.inter-caption_action {
  font-size: @size-extrasmall;
  font-weight: 400;
  line-height: @line-height-small;
  text-decoration: underline;
}
.inter-caption-grey {
  font-size: @size-tiny;
  font-weight: 400;
  line-height: @line-height-small;
  color: #868698;
}
.inter-caption-grey_extrasmall {
  font-size: @size-extrasmall;
  font-weight: 400;
  line-height: @line-height-small;
  color: #868698;
}
