@import '_variables';
@import '_utils';
@import '_overrides';
@import '_fonts';
@defaultFontFamily: 'Inter', sans-serif;

html,
body {
  margin: 0;
  padding: 0;
  font-family: @defaultFontFamily;
}
img {
  user-select: none;
  -webkit-user-drag: none;
  cursor: 'pointer';
}
button {
  cursor: 'pointer';
}
