// Tabs

.ant-tabs {
  & > .ant-tabs-content-holder,
  .ant-tabs > div > .ant-tabs-content-holder {
    order: 1;
  }
  & > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin-top: 0;
    margin-bottom: 16px;
  }
  .ant-tabs-tab {
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @text-color;
      font-weight: normal;
    }
    &:not(:hover) {
      color: @text-color-secondary;
    }
  }
}

// Select

.ant-select-dropdown {
  border-radius: @border-radius-sm;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: @border-radius-sm;
}
